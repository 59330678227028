import VerticalTextCarousel from "../components/vertical-text-carousel";
import Navbar from "../components/navbar";
import Slideshow from "../components/slideshow";
import IntroduceMe from "../components/introduce-me";
import ProjectShowcase from "../components/project-showcase";
import ProjectShowcaseReverse from "../components/project-showcase-reverse";
import ProjectShowcaseVerticalColumn from "../components/project-showcase-vert-col";
import ProjectSmall from "../components/project-small";
import ProjectSmallVertical from "../components/project-small-vertical";
import ContactMe from "../components/contact-me";

import { projects } from "../data/projects";
import { carousel } from "../data/carouse_images";

import "../styles/main.css";

export default function Portfolio() {
  const colors = {
    first: "#e97a2c",
    second: "#f4bd6e",
    third: "#ffd7b2",
    fourth: "#d2e5ff",
    fifth: "#00a1cf",
  };
  const colors2 = {
    first: "#ffa370",
    second: "#ffd576",
    third: "#e67370",
    fourth: "#5482e6",
    fifth: "#65b1ff",
  };
  const colors3 = {
    first: "#ff8c66",
    second: "#ffa166",
    third: "#ffb766",
    fourth: "#f4c466",
    fifth: "#d8c466",
  };

  const qualities = [
    { name: "creative", color: colors.fifth },
    { name: "reliable", color: colors3.fourth },
    { name: "outgoing", color: colors3.third },
    { name: "adaptable", color: colors3.second },
    { name: "a team-player", color: colors3.first },
    { name: "innovative", color: colors.second },
    /*{name: 'and you should too', color: colors2.fifth}*/
    { name: "open to work!", color: colors2.fifth },
  ];
  /*{name: 'open to work!', color: colors2.fifth}]*/

  // eslint-disable-next-line
  const projectShowcaseDesc =
    "I showcase my projects with a blend of creativity and clarity. \
                                Through visually engaging presentations and concise documentation, \
                                I ensure that my work stands out. I believe in highlighting not only the \
                                final results but also the journey, emphasizing problem-solving and innovation.";

  const skills = [
    "C",
    "C++",
    "C#",
    "Java",
    "Erlang",
    "Elixir",
    "Prolog",
    "SQL",
    "Swift",
    "Python",
    "MIPS Assembly",
    "HTML",
  ];

  return (
    <>
      <Navbar active="Portfolio" />

      <div className="md:max-width-body max-w-fit">
        <div className="mx-8 mt-12 grid grid-cols-4 gap-6 max-md:text-center md:mx-80 md:mt-60 md:grid-cols-12 md:gap-16">
          <img
            src="/assets/images/logo_op_cutout.png"
            alt="Logo with cutout from one of Oscar's projects"
            className="col-span-4 drop-shadow-xl filter md:hidden md:scale-150 max-md:max-w-[300px] max-md:m-auto"
          />
          <div className="col-span-4 mt-10 md:col-span-8 md:mt-0">
            <p className="m-auto whitespace-pre-line text-4xl font-bold md:text-7xl">
              {"My name is Oscar."}
            </p>
            <p className="m-auto whitespace-pre-line text-4xl font-bold md:text-7xl">
              {"I am"}
            </p>
            <VerticalTextCarousel
              className="absolute isolate max-md:text-center"
              items={qualities}
            />
          </div>
          <div className="col-span-4 max-md:hidden">
            <img
              src="/assets/images/logo_op_cutout.png"
              alt="Logo with cutout from one of Oscar's projects"
              className="drop-shadow-xl filter md:scale-150"
            />
          </div>
        </div>

        <IntroduceMe />
      </div>

      <Slideshow project_images={carousel} />

      <ProjectShowcase project={projects[2]} len={283} />
      <ProjectShowcaseReverse project={projects[0]} len={270} />
      <ProjectShowcaseVerticalColumn project={projects[5]} len={250} />

      <div className="max-width-body">
        <div className="my-40">
          <div>
            {/**
             * TITLE GOES HERE.
             */}
            {/*<p className="m-auto whitespace-pre-line text-center text-7xl font-semibold">
              {"And some more..."}
            </p>*/}
          </div>

          <ProjectSmall
            project={projects[6]}
            colour={"bg-white"}
            titleColour={"text-sky-400"}
            textColour={"text-black"}
          />
          <ProjectSmallVertical
            project1={projects[3]}
            project2={projects[4]}
            colour={"bg-white"}
            titleColour1={"text-emerald-400"}
            titleColour2={"text-fuchsia-400"}
            textColour={"text-black"}
          />
          <ProjectSmall
            project={projects[1]}
            colour={"bg-white"}
            titleColour={"text-yellow-400"}
            textColour={"text-black"}
          />
        </div>

        <div className="mb-10" />
      </div>

      {/*<ContactMe />*/}
    </>
  );
}
