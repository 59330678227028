import { React } from "react";
import TextCompress from "./text-compress";

export default function ProjectSmallVertical(props) {
  function skillBadge(skill) {
    return (
      <div key={skill} className="badge-default badge badge-lg m-0.5 h-8">
        {skill}
      </div>
    );
  }

  function verticalColumn(project, titleColour) {
    return (
      <div
        className={`lg:px-20 hero min-h-fit rounded-xl max-md:col-span-12 md:col-span-6 ${props.colour}`}
      >
        <div className="lg:flex-col hero-content flex-col">
          <div className="pb-5 pt-8 max-md:mx-8 md:px-10">
            <h1
              className={`text-5xl font-medium md:text-center ${titleColour}`}
            >
              {project.title}
            </h1>
            <p
              className={`mt-5 whitespace-pre-line py-3 md:text-center ${props.textColour}`}
            >
              {" "}
              <div className="md:hidden">
                <TextCompress text={project.description} maxLength={250} />
              </div>
              <span className="max-md:hidden">{project.description}</span>{" "}
            </p>
          </div>

          <img
            src={project.link_image}
            className="aspect-video rounded-lg shadow-2xl max-md:max-w-md max-md:max-w-xs md:max-w-xl"
          />

          <div className="mb-16 max-md:mx-8 md:px-10">
            <div className="pt-10">{project.skills.map(skillBadge)}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-5 grid cursor-default grid-cols-12 gap-6 max-md:px-4 md:mx-40">
      {verticalColumn(props.project1, props.titleColour1)}
      {verticalColumn(props.project2, props.titleColour2)}
    </div>
  );
}
