import { React } from "react";
import TextCompress from "./text-compress";

export default function ProjectSmall(props) {
  function skillBadge(skill) {
    return (
      <div key={skill} className="badge-default badge badge-lg m-0.5 h-8">
        {skill}
      </div>
    );
  }

  return (
    <div className="mt-5 grid cursor-default grid-cols-12 gap-6 max-md:px-4 md:px-40">
      <div
        className={`hero col-span-12 h-[100%] min-h-fit rounded-xl ${props.colour}`}
      >
        <div className="lg:flex-row hero-content flex w-full flex-col items-center justify-center">
          <div className="lg:w-2/3 w-full px-4 py-8 md:px-10 md:pt-20">
            <h1 className={`text-5xl font-medium ${props.titleColour}`}>
              {props.project.title}
            </h1>
            <p className={`mt-5 whitespace-pre-line py-3 ${props.textColour}`}>
              <div className="md:hidden">
                <TextCompress
                  text={props.project.description}
                  maxLength={250}
                />
              </div>
              <span className="hidden md:block">
                {props.project.description}
              </span>
            </p>
            <div className="pt-10">{props.project.skills.map(skillBadge)}</div>
          </div>
          <div className="lg:w-1/3 flex w-full items-center justify-center px-4 py-8">
            <img
              src={props.project.link_image}
              className="rounded-lg shadow-2xl max-md:mx-8 md:max-w-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

/*import { React } from "react";
import TextCompress from "./text-compress";

export default function ProjectSmall(props) {
  function skillBadge(skill) {
    return (
      <div key={skill} className="badge-default badge badge-lg m-0.5 h-8">
        {skill}
      </div>
    );
  }

  return (
    <div className="mt-5 grid cursor-default grid-cols-12 gap-6 md:mx-40">
      <div
        className={`hero col-span-12 h-[100%] min-h-fit rounded-xl md:px-20 ${props.colour}`}
      >
        <div className="lg:flex-row hero-content flex-col">
          <div className="col-span-8 max-md:py-8 md:px-10 md:pt-20">
            <h1
              className={`text-5xl font-medium max-md:mx-8 ${props.titleColour}`}
            >
              {props.project.title}
            </h1>
            <p
              className={`mt-5 whitespace-pre-line py-3 max-md:mx-8 ${props.textColour}`}
            >
              {" "}
              <div className="md:hidden">
                <TextCompress
                  text={props.project.description}
                  maxLength={250}
                />
              </div>
              <span className="max-md:hidden">{props.project.description}</span>{" "}
            </p>

            <div className="pt-10 max-md:mx-8">
              {props.project.skills.map(skillBadge)}
            </div>
          </div>

          <div className="py-8">
            <img
              src={props.project.link_image}
              className="col-span-4 rounded-lg shadow-2xl
                                        max-md:mx-8 max-md:max-w-md md:max-w-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
}*/
