import Portfolio from "./views/portfolio";
import Resume from "./views/resume";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"

function App()
{

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Navigate to="/portfolio" />} />
                <Route exact path='/portfolio' element={<Portfolio />} />
                {<Route exact path='/resume' element={<Resume />} />}
                {/*<Route path='*' element={<Page404 />}/>*/}
            </Routes>
        </BrowserRouter>
    )

}

export default App;
