import { useState } from "react";

const TextCompress = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!text) {
    return null;
  }

  if (text.length <= maxLength) {
    return <p>{text}</p>;
  }

  return (
    <div>
      <p>{isExpanded ? text : `${text.slice(0, maxLength)} ... `}</p>
      <p>
        <button
          className="btn btn-link btn-xs px-0"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span>{isExpanded ? "read less" : "read more"}</span>
        </button>
      </p>
    </div>
  );
};

export default TextCompress;
