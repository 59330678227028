import TextCompress from "./text-compress";

export default function ProjectShowcase(props) {
  function skillBadge(skill) {
    return (
      <div key={skill} className="badge badge-primary badge-lg m-1 h-8">
        {skill}
      </div>
    );
  }

  return (
    <div className="col-span-4 w-screen overflow-hidden bg-gradient-to-t from-[#f5f5f7] to-white to-25% py-12 md:col-span-12 md:py-40">
      <div className="max-width-body grid grid-cols-4 gap-16 md:grid-cols-12">
        <div className="relative col-span-6 max-md:block md:hidden">
          {/* Overlay to create fade effect */}
          <img
            className="relative mx-auto h-5/6 w-5/6 rounded-lg object-cover md:mx-0 md:h-full md:w-full md:rounded-l-lg"
            key={props.project.link_image}
            src={props.project.link_image}
            draggable="false"
            alt=""
          />
        </div>
        <div className="col-span-6 flex-col max-md:mx-12 md:col-span-6 md:mx-40">
          <h1 className="text-4xl font-medium text-emerald-400 md:text-6xl">
            {props.project.title}
          </h1>
          <h1 className="mt-1 text-xl font-medium md:text-4xl">
            {props.project.subtitle}
          </h1>
          <h4 className="text-md mt-5 font-medium text-zinc-400 md:text-2xl">
            <div className="md:hidden">
              <TextCompress
                text={props.project.description}
                maxLength={props.len}
              />
            </div>
            <span className="max-md:hidden">{props.project.description}</span>
          </h4>
          <div className="pt-10">{props.project.skills.map(skillBadge)}</div>
        </div>
        <div className="relative col-span-6 max-md:hidden md:block">
          {/* Overlay to create fade effect */}
          <img
            className="relative mx-auto h-5/6 w-5/6 rounded-lg object-cover md:mx-0 md:h-full md:w-full md:rounded-l-lg"
            key={props.project.link_image}
            src={props.project.link_image}
            draggable="false"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
