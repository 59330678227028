import { React, useState, useEffect } from "react";

import "../styles/verticalTextCarousel.css";

export default function VerticalTextCarousel(props) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  function parseDataList(items) {
    let temp = [];

    for (let i = 0; i < items.length; i++) {
      temp = [...temp, { item: items[i], id: i }];
    }

    temp = [...temp, { item: items.at(-1), id: items.length }];

    return temp;
  }

  return isLoaded ? (
    <div className="container relative bottom-2 m-0 max-md:mt-8">
      <ul className="list">
        {parseDataList(props.items).map((elem) => (
          <li
            key={elem.id}
            className="list-item text-[50px] font-semibold md:text-[85px]"
          >
            <p
              style={{ color: elem.item.color }}
              className="align-middle max-md:text-center"
            >
              {elem.item.name}
            </p>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <p
      style={{ color: parseDataList(props.items)[0].item.color }}
      className="relative bottom-2 m-0 align-middle text-[50px] font-semibold max-md:mt-8 md:text-[85px]"
    >
      {parseDataList(props.items)[0].item.name}
    </p>
  );
}
