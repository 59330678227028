import { React, useEffect } from "react";

import "../styles/imageCarousel.css";

export default function Showcase(props) {
  var track;

  useEffect(() => {
    track = document.getElementById("image-track");
  });

  const handleOnDown = (e) => (track.dataset.mouseDownAt = e.clientX);

  const handleOnUp = () => {
    if (!track) return;

    track.dataset.mouseDownAt = "0";
    track.dataset.prevPercentage = track.dataset.percentage;
  };

  const handleOnMove = (e) => {
    if (!track || track.dataset.mouseDownAt === "0") return;

    const mouseDelta = parseFloat(track.dataset.mouseDownAt) - e.clientX,
      maxDelta = window.innerWidth / 2;

    const percentage = (mouseDelta / maxDelta) * -100,
      nextPercentageUnconstrained =
        parseFloat(track.dataset.prevPercentage) + percentage,
      nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);

    track.dataset.percentage = nextPercentage;

    track.animate(
      {
        transform: `translate(${nextPercentage}%, 0%)`,
      },
      { duration: 1200, fill: "forwards" }
    );

    for (const image of track.getElementsByClassName("image")) {
      image.animate(
        {
          objectPosition: `${100 + nextPercentage}% center`,
        },
        { duration: 1200, fill: "forwards" }
      );
    }
  };

  /* -- Had to add extra lines for touch events -- */

  window.onmousedown = (e) => handleOnDown(e);

  window.ontouchstart = (e) => handleOnDown(e.touches[0]);

  window.onmouseup = (e) => handleOnUp(e);

  window.ontouchend = (e) => handleOnUp(e.touches[0]);

  window.onmousemove = (e) => handleOnMove(e);

  window.ontouchmove = (e) => handleOnMove(e.touches[0]);

  function generateTrackImages(source) {
    return (
      <img
        className="image"
        key={source.index}
        src={source.link}
        draggable="false"
        alt="Project Image on slide track"
      />
    );
  }

  useEffect(() => {
    track = document.getElementById("image-track");

    // Initial slide animation
    const initialSlide = () => {
      const initialPercentage = -20; // Double the initial slide distance

      track.animate(
        {
          transform: `translate(${initialPercentage}%, 0%)`,
        },
        { duration: 8000, fill: "forwards" }
      ); // Quadruple the duration for quarter speed

      for (const image of track.getElementsByClassName("image")) {
        image.animate(
          {
            objectPosition: `${100 + initialPercentage}% center`,
          },
          { duration: 8000, fill: "forwards" }
        ); // Quadruple the duration for quarter speed
      }

      // No need to reset position after initial slide
    };

    initialSlide();
  });

  return (
    <div className="image-track-container">
      <div id="image-track" data-mouse-down-at="0" data-prev-percentage="0">
        {props.project_images.map(generateTrackImages)}
      </div>
    </div>
  );
}
