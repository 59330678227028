import Showcase from "../views/showcase";
import { React } from "react";

import "../styles/main.css";

export default function Slideshow(props) {
  // eslint-disable-next-line
  const projectShowcaseDesc =
    "I showcase my projects with a blend of creativity and clarity. \
                                    Through visually engaging presentations and concise documentation, \
                                    I ensure that my work stands out. I believe in highlighting not only the \
                                    final results but also the journey, emphasizing problem-solving and innovation.";

  return (
    <div
      className="col-span-12 my-12 md:my-40 w-screen overflow-hidden
                            bg-gradient-to-t from-[#f5f5f7] from-10% to-white to-25%"
    >
      <div className="max-width-body">
        <div className="mx-10 mt-12 md:mt-40 flex-col md:mx-40">
          <h1 className="text-6xl font-medium text-emerald-400">
            What I have been doing
          </h1>
          <h1 className="mt-1 text-xl font-semibold md:text-6xl md:font-medium">
            {"A showcase of some of my projects"}
          </h1>
          <h4 className="text-md mt-5 font-medium text-zinc-400 md:mr-96 md:text-2xl">
            {projectShowcaseDesc}
          </h4>
        </div>
        <div className="mb-10">
          <Showcase project_images={props.project_images} />
        </div>
      </div>
    </div>
  );
}
