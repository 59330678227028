import { React } from "react";
import TextCompress from "./text-compress";

export default function ProjectShowcaseVerticalColumn(props) {
  function skillBadge(skill) {
    return (
      <div key={skill} className="badge badge-primary badge-lg m-1 h-8">
        {skill}
      </div>
    );
  }

  return (
    <div className="col-span-4 w-screen overflow-hidden bg-white py-12 md:col-span-12 md:py-40">
      <div className="max-width-body grid grid-cols-4 gap-16 md:grid-cols-12">
        <div className="col-span-6 flex-col max-md:mx-12 md:col-span-12 md:mx-40">
          <h1 className="text-left text-4xl font-medium text-yellow-400 md:text-center md:text-6xl">
            {props.project.title}
          </h1>
          <h1 className="mt-1 text-left text-xl font-medium md:text-center md:text-4xl">
            {props.project.subtitle}
          </h1>
          <h4 className="text-md mt-5 font-medium text-zinc-400 md:mx-80 md:mt-10 md:whitespace-pre-line md:text-center md:text-2xl">
            <div className="md:hidden">
              <TextCompress
                text={props.project.description}
                maxLength={props.len}
              />
            </div>
            <span className="max-md:hidden">{props.project.description}</span>
          </h4>
        </div>
        <div className="col-span-6 flex flex-col items-center justify-center md:col-span-12">
          <img
            className="image max-w-xl md:translate-x-4 content-center rounded-lg max-md:relative max-md:mx-auto max-md:h-5/6 max-md:w-5/6"
            key={props.project.link_image}
            src={props.project.link_image}
            draggable="false"
            alt="Project Image for showcase"
          />

          <div className="md:mx-60 pt-10 max-md:px-8">
            {props.project.skills.map(skillBadge)}
          </div>

        </div>
      </div>
    </div>
  );
}
