export const carousel =
[
    {
        index: 0,
        link: "./assets/images/Projects/Sof-SpiritedAwayTrain.png",
    },
    {
        index: 1,
        link: "./assets/images/Projects/Sof-SpiritedAwayAnEscape.png",
    },
    {
        index: 2,
        link: "./assets/images/Projects/Sof-Nightkiosk.png",
    },
    {
        index: 3,
        link: "./assets/images/Projects/Spiderverse-Train.jpeg",
    },
    {
        index: 4,
        link: "./assets/images/Projects/Boids.png",
    },
    {
        index: 5,
        link:"./assets/images/Projects/Spiderverse-Bagel.jpeg",
    },
]