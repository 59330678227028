import { React } from "react";
import { Link } from "react-router-dom";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";

export default function Navbar(props) {
  const options = ["Portfolio", "Resume"];

  const socialLinks = [
    {
      link: "https://www.linkedin.com/in/oscar-zhpersson/",
      username: "oscar-zhpersson",
      icon: <AiFillLinkedin size={25} />,
    },
    {
      link: "https://github.com/oscarzhpersson",
      username: "oscarzhpersson",
      icon: <AiFillGithub size={25} />,
    },
  ];

  function generateSocialLink(set) {
    return (
      <Link key={set.link} to={set.link} target="_blank" className="mx-2">
        <button>{set.icon}</button>
      </Link>
    );
  }

  function generateOptions(option) {
    return (
      <div key={option}>
        <Link to={"/" + option.toLowerCase()}>
          <button className="px-3">
            {option === props.active ? (
              <p className="text-sm font-light text-slate-500">{option}</p>
            ) : (
              <p className="text-sm font-light">{option}</p>
            )}
          </button>
        </Link>
      </div>
    );
  }

  return (
    <div className="navbar bg-base-100 drop-shadow-sm print:hidden">
      <div className="navbar-start md:ml-80">
        <a className="ml-4 text-xl font-bold normal-case md:m-0">
          Oscar Persson
        </a>
      </div>
      <div className="navbar-end max-md:hidden md:mr-80">
        {options.map(generateOptions)}
        {socialLinks.map((social) => {
          return generateSocialLink(social);
        })}
        {/*<button href='contact' className="btn btn-xs rounded-full bg-blue-500 border-none mx-1">
                    <p className="normal-case text-xs font-light">Contact</p>
                </button>*/}
      </div>
      <div className="navbar-end mr-4 md:mr-80 md:hidden">
        <Link
          to={
            "/" +
            (props.active === "Portfolio"
              ? "Resume"
              : "Portfolio"
            ).toLowerCase()
          }
        >
          <button className="btn btn-ghost btn-link text-black">
            {props.active === "Portfolio" ? "Resume" : "Portfolio"}
          </button>
        </Link>
      </div>
    </div>
  );
}
