import Navbar from "../components/navbar";

import { Link } from "react-router-dom";
import { desc, skills, education, work, socialLinks } from "../data/resume";

export default function Resume() {
  function generateSocialLink(set) {
    return (
      <Link key={set.link} to={set.link} target="_blank">
        <button>{set.icon}</button>
      </Link>
    );
  }

  function generateListSet(title, set) {
    return (
      <div
        key={title}
        className="col-span-1 text-left print:col-span-4 print:mx-8 max-md:w-screen md:col-span-4"
      >
        <ul className="list-none space-y-2 text-center print:text-left max-md:w-screen md:text-left">
          <li>
            <p className="font-bold print:text-sm">{title}</p>
          </li>
          {set.map((el) => {
            return (
              <li key={el}>
                <p className="print:text-sm">{el}</p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  function generateEducationComponent(set) {
    return (
      <div
        key={set.degree}
        className="mt-7 gap-16 text-center print:mx-8 print:grid print:grid-cols-12
                    print:gap-8 print:text-left max-md:w-screen md:grid md:grid-cols-12 md:text-left"
      >
        <div className="col-span-12 print:col-span-6 md:col-span-4">
          <ul className="list-none space-y-2 max-md:w-screen">
            <li>
              <h3 className="m-auto px-8 text-2xl font-bold print:my-0 print:px-0 print:text-base print:font-semibold md:px-0">
                {set.degree}
              </h3>
            </li>
            <li className="hidden print:block">
              <p className="font-bold print:text-sm">{set.institution}</p>
            </li>
            <li>
              <p className="text-xs">{set.timeFrame}</p>
            </li>
          </ul>
        </div>
        <p className="col-span-12 font-bold print:my-0 print:hidden print:text-sm md:col-span-4">
          {set.institution}
        </p>
        <div className="col-span-12 print:col-span-6 md:col-span-4">
          <ul className="mt-8 list-none space-y-2 print:mt-0 max-md:w-screen md:mt-0">
            <li>
              <p className="m-auto font-bold print:text-sm">Relevant Courses</p>
            </li>
            {set.courses.map((el) => {
              return (
                <li key={el}>
                  <p className="print:text-sm">{el}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  function generateExperienceComponent(set) {
    return (
      <div
        key={set.company}
        className="mb-8 mt-8 grid-cols-12 gap-16 print:mb-0
                    print:mt-8 print:grid print:gap-4 max-md:w-screen md:mb-0 md:mt-7
                    md:grid"
      >
        <div className="col-span-12 text-center print:text-left md:col-span-4 md:text-left">
          <ul className="list-none space-y-2 max-md:w-screen">
            <li>
              <h3 className="m-auto px-8 text-2xl font-bold print:text-lg print:font-semibold md:px-0 md:px-0">
                {set.company}
              </h3>
            </li>
            <li>
              <p className="print:px-8 print:text-sm">{set.timeFrame}</p>
            </li>
          </ul>
        </div>
        <div className="col-span-8 text-center print:text-left print:text-sm md:text-left">
          <ul className="list-none space-y-2 max-md:w-screen">
            <li>
              <p className="m-auto px-8 font-bold md:px-0">{set.title}</p>
            </li>
            <li>
              <p className="px-8 md:px-0">{set.desc}</p>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <>
      <Navbar active="Resume" className="print:hidden" />

      <div className="max-width-body">
        <div className="mt-20 print:mx-0 print:mt-12 md:mx-80 md:grid md:grid-cols-12 md:gap-16">
          <div className="md:col-span-12">
            <div className="md:mb-5 md:flex md:justify-start">
              <h1 className="whitespace-pre-line text-center text-3xl font-bold tracking-tighter print:mx-8 print:text-left md:text-7xl">
                Oscar Persson
              </h1>
              <div className="mt-4 inline flex items-center justify-center gap-8 print:hidden sm:items-start sm:justify-start md:ml-8 md:mt-10">
                {socialLinks.map((social) => {
                  return generateSocialLink(social);
                })}
              </div>
            </div>
            <h2 className="text-md m-auto mx-8 mt-10 whitespace-pre-line text-center print:mt-4 print:text-left print:text-sm md:m-0 md:text-left md:text-2xl">
              {desc}
            </h2>
          </div>
        </div>

        <div className="grid-cols-4 gap-16 print:mx-0 md:mx-80 md:grid md:grid-cols-12">
          <div className="col-span-4 mt-20 print:mt-12 md:col-span-12">
            <h3 className="m-auto mb-3 whitespace-pre-line text-center text-xl tracking-widest print:mx-8 print:text-left md:text-left">
              SKILLS
            </h3>
            <hr className="dark:bg-gray-700 h-px border-0 bg-gray-200 print:mx-8 print:border-t print:border-gray-200" />

            <div className="mt-7 grid grid-cols-1 gap-16 print:grid-cols-12 md:grid-cols-12">
              {generateListSet("Languages", skills.lang)}
              {generateListSet("Applications", skills.apps)}
              {generateListSet("Frameworks", skills.frameworks)}
            </div>

            <div className="col-span-12 mt-20">
              <h3 className="m-auto mb-3 whitespace-pre-line text-center text-xl tracking-widest print:mx-8 print:text-left md:text-left md:text-left">
                EDUCATION
              </h3>
              <hr className="dark:bg-gray-700 h-px border-0 bg-gray-200 print:mx-8 print:border-t print:border-gray-200" />

              {education.map((edu) => {
                return generateEducationComponent(edu);
              })}
            </div>

            <div className="col-span-12 my-20">
              <h3 className="m-auto mb-3 whitespace-pre-line text-center text-xl tracking-widest print:mx-8 print:text-left md:text-left md:text-left">
                EXPERIENCE
              </h3>
              <hr className="dark:bg-gray-700 h-px border-0 bg-gray-200 print:mx-8 print:border-t print:border-gray-200" />

              {work.map((com) => {
                return generateExperienceComponent(com);
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
