import { React } from "react";
import {
  description,
  descLarge,
  descSmall,
  skills,
} from "../data/introduction.js";

export default function IntroduceMe() {
  function SkillBadge(skill) {
    return (
      <div
        key={skill}
        className={
          "badge badge-outline badge-lg m-0.5 h-8 hover:scale-110 hover:bg-gray-800 hover:text-white"
        }
      >
        {skill}
      </div>
    );
  }

  return (
    <div className="mt-60 grid cursor-default grid-cols-4 md:mx-40 md:grid-cols-12 md:gap-6">
      <div className="col-span-4 min-h-fit rounded-xl bg-white px-40 max-md:mb-12 md:col-span-12">
        <div className="hero-content max-md:flex-col lg:flex-row">
          <img
            src="/assets/profile-pictures/ItsAMe.png"
            /*className="w-50 max-w-sm rounded-lg shadow-2xl max-md:mt-2"*/
            className="w-50 max-w-sm rounded-lg drop-shadow-2xl max-md:mt-2"
          />
          <div className="py-4 md:px-10 md:py-20">
            <h1 className="text-xl font-semibold max-md:text-left md:text-5xl md:font-medium">
              Meet your new developer!
            </h1>
            <p className="text-md mt-5 text-left font-medium text-zinc-400 md:text-2xl">
              {" "}
              {descSmall}{" "}
            </p>
            <div className="mt-4 flex flex-row gap-32 md:mt-10">
              <div>
                <p className="text-center text-5xl font-bold text-purple-400 md:text-left">
                  3
                </p>
                <p className="md:text-md text-center text-sm md:text-left">
                  Years of industry experience
                </p>
              </div>
              <div>
                <p className="text-center text-5xl font-bold text-orange-400 md:text-left">
                  13
                </p>
                <p className="md:text-md text-center text-sm md:text-left">
                  Years of total experience
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero col-span-4 min-h-fit rounded-xl bg-white px-4 max-md:pt-12 md:col-span-6 md:px-10">
        <div className="hero-content flex-col">
          <h1 className="text-5xl font-medium text-yellow-400">What I do</h1>
          <h4 className="text-center text-2xl text-xl font-semibold md:text-left md:font-medium">
            My skills, languages, tools, and frameworks
          </h4>
          <h4 className="text-5xl font-bold">{"</>"}</h4>
        </div>
      </div>
      <div className="hero col-span-4 min-h-fit rounded-xl bg-white md:col-span-6 md:px-10">
        <div className="px-5 py-10 max-md:flex max-md:flex-wrap max-md:justify-center">
          {skills.map(SkillBadge)}
        </div>
      </div>

      <div className="hero col-span-4 min-h-fit rounded-xl bg-white max-md:mt-12 md:col-span-12 md:px-40">
        <div className="hero-content flex-col lg:flex-row">
          <div className="px-10 py-4 md:py-20">
            <div className="flex w-full flex-col lg:flex-row">
              <div className="grid h-32 flex-grow place-items-center">
                <h3 className="text-right text-base text-xl font-semibold md:text-left md:text-2xl md:font-medium">
                  Frontend Engineer | Backend Engineer | Fullstack Engineer |
                  Software Engineer | QA Engineer | Manager | Designer | Game
                  Developer | Oscar
                </h3>
              </div>
              <div className="divider lg:divider-horizontal" />
              <div className="grid h-32 flex-grow place-items-center">
                <h1 className="text-right text-5xl font-medium text-violet-400 md:text-left">
                  What I've been called
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<div className="col-span-12 hero min-h-fit bg-white px-40 rounded-xl">
                    <div className="hero-content flex-col lg:flex-row">
                        <div className="px-10 py-20">
                            
                            <div className="flex flex-col w-full lg:flex-row">
                                <div className="grid flex-grow h-32 place-items-center">
                                    <h1 className="text-4xl font-medium">Meet my previous employers</h1>
                                </div> 
                                <div className="divider lg:divider-horizontal"/>
                                <div className="grid flex-grow h-32 place-items-center">
                                    <h1 className="text-5xl font-medium">Meet my previous employerssssssssssssss</h1>
                                </div> 
                            </div>

                        </div>
                    </div>
                </div>*/}
    </div>
  );
}
