export const projects =
[
    /*{
        title: "Empty Project",
        description: "",
        skills: ['', '', ''],
        link_image: "",
        link_github: "",
    },*/
    {
        title: "MTG Deckpick",
        subtitle: "Tinder for Magic: The Gathering",
        description: "A Tinder inspired deck-builder for the popular trading card game (TCG), Magic. The Gathering (MTG). The application's purpose is inspired by the concept of 'Hot-or-not?' where a user may add cards to their decks based on the cards appearances in the images. The project is written using ReactJS and the NextUI library. The trading-card data is fetched using the popular Skryfall API.\
        \n\n\
        The project was made as a personal project over the course of a couple of days in order to fascilitate the learning of web application development using React and Javascript.",
        skills: ['React.js', 'NextUI', 'Front-End Development', 'UI Design', 'Web Development', 'Git', 'Javascript', 'CSS', 'Node.js', 'MVP', 'Firestore'],
        link_image: "./assets/images/Projects/Deckpick.gif",
        link_github: "https://github.com/oscarzhpersson/deckpick",
    },
    {
        title: "Continuous Integration Tool",
        subtitle: "",
        description: "This CI-CD program aims to implement three core features of Continuous Integration - Compilation, Testing, and Notification through compilation.py, test.py and notification.py respectively.\
        \n\n\
        It was written as a student project for the course DD2480 - Software Engineering Fundamentals at KTH. The program builds, tests the application and delivers an outcome for the written program. The CI program iself is fully documented as well as fully tested.",
        skills: ['Python', 'Back-End Development', 'Continuous Integration', 'Flask', 'DevOps', 'Git', 'Rest', 'Github Actions', 'Webhooks'],
        link_image: "./assets/images/Projects/CI.png",
        link_github: "https://github.com/oscarzhpersson/DD2480_Lab2-CI",
    },
    {
        title: "Slice of: Spirited Away Train",
        subtitle: "A Spirited Away re-imagining",
        description: "The \"Slice of-\" project is a passion-project of mine where I take scenes from animated movies and bring them to life in full 3D using Blender and the Unity 3D Engine. The project spanned ~1 day's time.\
        \n\n\
        In order to study and learn the High Definition Render Pipeline (HDRP) within the Unity engine a scene from the popular Studio Ghibli movie Spirited Away was made. This particular scene was chosen because of its stunning presentation and impactfulness in the movie but also because of its visual complexity in displaying Miyazaki's \"Ma\" (calm). The project was made from scratch and aims to display the engine's capabilities for creating visually stunning, realistic graphics in Unity.",
        skills: ['Unity3D', 'Blender', 'Substance Painter 3D', 'HDRP', '3D Modelling', 'Game Development', 'C#', 'Graphical Design', 'Texturing', 'Adobe Photoshop', 'Affinity Serif Photo'],
        link_image: "./assets/images/Projects/Sof-SpiritedAwayTrain.png",
        link_github: null,
    },
    {
        title: "Moviewer",
        subtitle: "",
        description: "This project was made for the project course DH2642, Interactive programming and the dynamic web at KTH. it was made in a group of 4 people over the course of 2 weeks.\
        \n\n\
        Moviewer is a movie playlist application that allows users to browse and pick their favorite movies and add/save them to their personalized playlists. The app also displays the availability of the movies across different streaming platforms. The project was built using a combination of Node.js, React.js, Javascript and Firebase, providing a seamless user experience and real-time data storage. The user interface is intuitive and easy to use, making it a perfect tool for movie enthusiasts. It was a great opportunity for us to apply our web development skills and create a functional and visually appealing application.",
        skills: ['React.js', 'NextUI', 'Front-End Development', 'UI Design', 'Web Development', 'Git', 'Javascript', 'CSS', 'Node.js', 'MVP', 'Firebase Storage', 'Firebase Auth'],
        link_image: "./assets/images/Projects/Moviewer.png",
        link_github: null,
    },
    {
        title: "Slice of: Spirited Away",
        subtitle: "",
        description: "The \"Slice of-\" project is a passion-project of mine where I take scenes from animated movies and bring them to life in full 3D using Blender and the Unity 3D Engine. The project spanned ~1 day's time.\
        \n\n\
        In order to study and learn the High Definition Render Pipeline (HDRP) within the Unity engine a scene from the popular Studio Ghibli movie Spirited Away was made. This particular scene was chosen because of its stunning presentation and impactfulness in the movie but also because of its visual complexity in displaying Miyazaki's \"Ma\" (calm). The project aims to display the engine's capabilities for creating visually stunning, realistic graphics in Unity.",
        skills: ['Unity3D', 'Blender', 'Substance Painter 3D', 'HDRP', '3D Modelling', 'Game Development', 'C#', 'Graphical Design', 'Texturing', 'Adobe Photoshop', 'Affinity Serif Photo'],
        link_image: "./assets/images/Projects/Sof-SpiritedAwayAnEscape.png",
        link_github: null,
    },
    {
        title: "Boids",
        subtitle: "Custom Implementation of the Boids algorithm",
        description: "This project as a study for the project course DH2323 - Introduction to Computer Graphics, at KTH. My project study focused on simulating the flocking behavior of birds, known as boids, using Unity 3D engine and Blender. I implemented the Craig Reynold's boid algorithm, which simulates the movement of a flock of birds based on three simple rules: separation, alignment, and cohesion. This algorithm allowed me to create a believable and realistic simulation of a flock of birds, with each individual bird responding to its surroundings and the movement of its peers. I also utilized Blender to create 3D models of the birds and integrated them into the Unity 3D environment.",
        skills: ['Unity3D', 'Blender', 'Substance Painter 3D', '3D Modelling', 'C#', 'Graphical Design', 'Texturing', 'Algorithms', 'Theoretical Computer Science'],
        link_image: "./assets/images/Projects/Boids.png",
        link_github: null,
    },
    {
        title: "Slice of: Nightkiosk",
        subtitle: "",
        description: "The \"Slice of-\" project is a passion-project of mine where I take scenes from animated movies or picturesand bring them to life in full 3D using Blender and the Unity 3D Engine.\
        \n\n\
        In order to study and learn the Universal Render Pipeline (URP) within the Unity engine a scene featuring a Japanese style kiosk at night was made. The project was made from scratch and aims to display the engine's capabilities for creating visually stunning, realistic graphics in Unity, that are also optimised for uses in lower-end devices such as mobile or web.",
        skills: ['Unity3D', 'Blender', 'Substance Painter 3D', 'URP', '3D Modelling', 'Texturing', 'Game Development', 'C#', 'Graphical Design', 'Adobe Photoshop', 'Affinity Serif Photo'],
        link_image: "./assets/images/Projects/Sof-Nightkiosk.png",
        link_github: null,
    },
];