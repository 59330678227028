import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { MdAlternateEmail } from "react-icons/md";

const desc =
  "A skilled software developer with expertise in both front-end and\
                back-end technologies across multiple programming languages and tech stacks.";

const skills = {
  lang: [
    "C++",
    "Java",
    "Swift",
    "Python",
    "Typescript",
    "Kotlin",
    "Ruby",
    "Clojure",
    "C#",
    "Javascript",
  ],

  apps: [
    "xCode",
    "Unity 3D Engine",
    "Docker",
    "Kibana",
    "Blender",
    "Substance Painter 3D",
    "MariaDB",
    "Unreal Engine",
    "AWS",
    "DataDog",
  ],

  frameworks: [
    "React",
    "Ruby on Rails",
    "NextJS",
    "React Native",
    "Vue",
    "Framer Motion",
    "CMake",
    "Tailwind CSS",
    "Spring",
    "Kafka",
  ],
};

const education = [
  {
    institution: "Kungliga Tekniska Högskolan",
    degree: "MSc Computer Science",
    timeFrame: "(Thesis project remaining)",
    courses: [
      "Software Engineering Fundamentals",
      "Advanced Algorithms",
      "Dynamic Programming & Interactive Web Design",
      "Large-Scale Software Development",
      "Automated Software Testing and DevOps",
      "Software Reliability",
    ],
  },

  {
    institution: "Kungliga Tekniska Högskolan",
    degree: "BSc Information & Communication Technology",
    courses: [
      "Data Storage Paradigms",
      "Modern Software Development",
      "Algorithms, Datastructures & Complexity",
      "Logic for Computer Scientists",
    ],
  },
];

const work = [
  {
    company: "Klarna",
    timeFrame: "June 2023 - Present",
    title: "Back-end Software Engineer",
    // eslint-disable-next-line
    desc: "Accomplished back-end Software Engineer at Klarna, specializing in the Debt Collection team. \
            Proficient in leveraging cutting-edge technologies such as Java, Spring, Kafka, and AWS \
            to drive efficient and effective solutions. Demonstrated expertise in developing robust \
            internal tools and applications, contributing significantly to operational efficiency and productivity. \
            Recognized for technical prowess, innovative problem solving, and ability to deliver high-performance, scalable systems.",
  },

  {
    company: "Spotify",
    timeFrame: "May 2022 - Oktober 2022",
    title: "Software QA Engineer",
    // eslint-disable-next-line
    desc: "At Spotify I worked as a consultant, handling internal release testing and quality assurance. \
                I was tasked with making sure that, through rigorous and systematic testing, each \
                release released without errors. This was done through a weekly testing \
                procedure to evaluate the stability of each release from a user's perspective.",
  },

  {
    company: "Skry",
    timeFrame: "Mars 2022 - May 2023",
    title: "Software Engineer",
    // eslint-disable-next-line
    desc: "On-site consultant worked as an ”expert for hire” where we are hired\
                for different projects as consultants within a range of areas, including but not limited to\
                back-end development, front-end development, graphical design.",
  },

  {
    company: "Kungliga Tekniska Högskolan",
    timeFrame: "2021 - 2023",
    title: "Teaching Assistant",
    // eslint-disable-next-line
    desc: "Teaching assisant at KTH  where responsibilities included: grading, assistive sessions, lectures. \
                Selected courses that I have been a TA for are: ID1019 (Programming 2), ID1206/ID1200 (Operating Systems), \
                DD2480 (Software Engineering Fundamentals), ID1021 (Algorithms & Datastructures).",
  },

  {
    company: "The chapter for Information and Communication technology",
    timeFrame: "2021 - 2023",
    title: "Board member and manager for the Business Relations Committee",
    // eslint-disable-next-line
    desc: "As a Board Member and Manager for the Business Relations Committee, \
    I spearheaded strategic partnerships, driving organizational growth through effective \
    relationship-building and negotiation. I excelled in aligning committee goals with \
    the organization's vision, fostering significant business developments and enhancing \
    our corporate stature through strategic planning and stakeholder engagement.",
  },

  {
    company: "Special Elektronik",
    timeFrame: "Augusti 2017 - September 2018",
    title: "Software- & Games Developer",
    // eslint-disable-next-line
    desc: "Worked with Architectural Visualisation technology in VR using the Unity games engine for\
        demonstration purposes and internal applications. One of these architectural visualisations involved\
        creating a scale model of the office building that was interactive and exploreable within VR. \
        Developed games and other software for both the VR platform, as well as the PC platform using the Unity games engine. \
        Demonstration of VR for potential customers.",
  },

  {
    company: "Games Developer and Designer",
    timeFrame: "2012 - 2018",
    title: "Freelancer",
    // eslint-disable-next-line
    desc: "Worked within the games industry as a freelancer, as well in collaboration with several companies.\
        Main tool of use was the Unity 3D engine.",
  },
];

const socialLinks = [
  {
    link: "https://www.linkedin.com/in/oscar-zhpersson/",
    username: "oscar-zhpersson",
    icon: <AiFillLinkedin size={25} />,
  },
  {
    link: "https://github.com/oscarzhpersson",
    username: "oscarzhpersson",
    icon: <AiFillGithub size={25} />,
  },
  {
    link: "mailto::oscar.zhpersson@gmail.com",
    username: "oscar.zhpersson@gmail.com",
    icon: <MdAlternateEmail size={25} />,
  },
];

export { desc, skills, education, work, socialLinks };
