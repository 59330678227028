export const description = "With expertise in a wide range of programming languages and technologies, \
                        Oscar has the knowledge and skills needed to bring ideas to life. \
                        Oscar's ability to think creatively and solve complex problems is unparalleled, \
                        and his dedication to quality and user experience is second to none.\n\n\
                        Whether working independently or as part of a team, Oscar is \
                        always focused on delivering the best possible outcomes. With a keen eye for \
                        detail and a commitment to staying up-to-date with the latest industry trends \
                        and technologies, he is constantly pushing the boundaries of what's possible."

// eslint-disable-next-line
export const descLarge = "I am a 24 year old software developer and computer science master's student, I am a highly motivated and dedicated individual with \
        a passion for programming. I have a strong foundation in back end technologies and development, while also being proficient with web develop \
        ment and am constantly seeking to learn and expand my skill set. I thrive in challenging environments and enjoy learning new things, making me \
        well-suited for a career in this constantly evolving field. \
        In addition to my technical skills, I am also a strong communicator and enjoy working with others to solve complex problems. I thrive in fast‑paced, \
        collaborative environments and am eager to contribute my skills and expertise to any team. Overall, I am a enthusiastic and driven developer who \
        is committed to using my skills to create innovative and effective solutions."

// eslint-disable-next-line
export const descSmall = "I am a 24 year old software developer and Computer Science Master's student with a professional background in Games development \
        and Software Development. I am a highly motivated and dedicated individual with \
        a passion for programming. I have a strong foundation in back end technologies and development, while also being proficient with web develop \
        ment. \
        I thrive in fast-paced, collaborative environments and am eager to contribute my skills and expertise to any team."

export const skills = [
                    'C', 'C++', 'C#', 'Java', 'Erlang', 'Elixir', 'Prolog', 'SQL', 'Swift',
                    'Python', 'MIPS Assembly', 'HTML', 'Typescript', 'Javascript',
                    'Clojure', 'CSS', 'Ruby', 'xCode', 'Unity 3D Engine', 'React',
                    'Vue', 'Jira', 'Ruby on Rails', 'NextJS', 'React Native', 'Visual Studio',
                    'Visual Studio Code', 'Git', 'MVP', 'MVVM', 'MySQL', 'Node.js',
                    'UX Design', 'UI Design', 'MariaDB', 'Continous Integration',
                    'DevOps', 'Docker', 'PyTest', 'CMake', 'Photoshop', 'Flask', 'Github Actions',
                    'BigQuery', 'Kibana', 'Kotlin', 'IntelliJ', 'Rider', 'CLion', 'Framer Motion',
                    'Blender', 'Substance Painter 3D', 'Adobe Photoshop', 'Affinity Serif Photo',
                    'Tailwind', 'SCSS/SASS', 'Jepsen', 'Unreal Engine', 'AWS', 'Spring', 'Kafka',
                    'Auth0 Okta', 'Firebase', 'Datadog', 'Github Copilot', 'GPT'
                    ]